import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import CertificationOption from "../components/CertificationOption"

export default function EuropeCertification() {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificaciones" },
    { label: "Europa (Core competence EFP, EFA, EIP y EIA)" },
  ]
  return (
    <Layout>
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Europa (Core competence EFP, EFA, EIP y EIA)"
          icon="fas fa-file-alt"
        />
        <span className="SpainCertification-text">
          EFPA, a nivel de toda Europa, acredita y certifica a los profesionales
          de la asesoría y planificación financiera personal en los siguientes
          certificados:
        </span>
        <div className="row efpa-row mb-3">
          <div className="col-12 col-md-6 Certification-padding-r">
            <CertificationOption
              icon="fas fa-project-diagram"
              title="EFP"
              text="European Financial Planner"
              link="/"
              usePdf
            />
            <CertificationOption
              icon="fas fa-user-tie"
              title="EFA"
              text="European Financial Advisor"
              link="/"
              usePdf
            />
          </div>
          <div className="col-12 col-md-6 Certification-padding-l">
            <CertificationOption
              icon="far fa-file-alt"
              title="EIP"
              text="European Investment Practitioner"
              link="/"
              usePdf
            />
            <CertificationOption
              icon="far fa-id-card"
              title="EIA"
              text="European Investment Assistant"
              link="/"
              usePdf
            />
          </div>
        </div>
        <span className="SpainCertification-text">
          Para ello se han desarrollado programas de formación y de exámenes
          específicos que posibilitan la obtención de estas licencias
          profesionales.
        </span>
        <span className="SpainCertification-text">
          EFPA ha elaborado unos programas de contenidos académicos, teóricos y
          prácticos que, a través de programas de formación específica o del
          autoestudio, posibilitan a los profesionales obtener, a través de
          exámenes, los siguientes certificados profesionales europeos.
        </span>
      </div>
    </Layout>
  )
}
